@use '../modules/respond';

.section--spcn2020 {
  margin: 0 1rem;
  display: grid;
  align-items: center;
  padding: 6rem 0;
}

.header {
  margin: 0 auto;
  text-align: center;

  @include respond.respondAbove(sm) {
    width: 30rem;
  }
}

#unitHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

#levelsWrapper {
  width: 5.5rem;
  background-color: var(--color-white);
  padding: .5rem .25rem .25rem .25rem;
  border: 1px solid var(--color-black);
  border-radius: 2rem;
  margin-right: .5rem;
}

.levelsWrapper--expanded {
  position: relative;

  .levels {
    display: block;
    position: absolute;
  }
}

.levels {
  width: 5rem;
  list-style: none;
  display: none;
  margin: 1rem 0 0 0;
  padding: .6rem 0 .4rem;
  background-color: var(--color-white);
  border-radius: .5rem;
  box-shadow: 0 .1rem .5rem var(--color-shadow);
}

.levelSelector {
  background: none;
  border: none;

  .dropDownIcon {
    padding: 0 1rem 0 .25rem;
  }

  .dropDown {
    width: 1.4rem;
    height: 1.4rem;
    vertical-align: top;
  }
}

.level {
  cursor: pointer;
  padding: 0 .2rem;
  margin: .3rem auto;
  width: 100%;
  border: none;
  background-color: transparent;
}

#wordList {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
}

.word {
  width: 99%;
  display: grid;
  grid-template-columns: 3rem 1fr;
  background-color: var(--color-white);
  border-radius: 2rem;
  margin: .25rem 0;
  padding: .5rem .25rem;
  align-items: center;
}

.word__ordinal {
  font-size: 1.8rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  margin-left: .5rem;
}

.word__player-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--color-black);
  border-radius: 2rem;
  margin-right: .5rem;
  padding: .25rem .25rem;
}

.word__marker {
  color: var(--color-white);
  font-size: 1.5rem;
  margin-left: 1.5rem;
}

.word__player--hidden,
.word__marker--hidden {
  display: none;
  transition: ease-in-out;
}

.word__play,
.word__toggle {
  border-radius: 2rem;
  border: 0;
  padding: .5rem 1rem;
  font-weight: 700;
}

.word__play {
  font-size: .8rem;
  background-color: var(--color-brand--orange);
  color: var(--color-white);
  transition: all ease-in-out;

  &:active,
  &:hover {
    background-color: var(--color-brand--cream);
    color: var(--color-black);
  }
}

.play__label {
  display: inline-block;
  padding-left: .25rem;
}

.word__toggle {
  text-transform: uppercase;
  background-color: var(--color-brand--green);

  &:active,
  &:hover {
    background-color: var(--color-brand--green--light);
  }

  &.word__toggle--toggled {
    background-color: var(--color-white);
  }
}

.diacritic {
  display: inline-block;
  width: .98em;
  text-indent: -999px;
  position: relative;
  vertical-align: baseline;

  &.macron-oo {
    background: url(assets/long-oo.svg);
    background-repeat: none;
    background-size: 100%;
    background-position: 0 5em;
  }

  &.breve-oo {
    background: url(assets/breve-oo.svg);
    background-repeat: none;
    background-size: 100%;
    background-position: 0 5em;
  }
}