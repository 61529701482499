
body {
  font-family: zb_zanuturabook, sans-serif;
  font-size: 1rem;
  background-color: var(--color-brand--blue);
}

h1 {
  font-family: zb_zanuturabook_bold, sans-serif;
  font-size: 2rem;
  line-height: 2rem;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: zb_zanuturabook_bold, sans-serif;
  font-size: 1rem;
  margin: 0;
}

strong,
b {
  font-family: zb_zanuturabook_bold, sans-serif;
}

button {
  cursor: pointer;
}