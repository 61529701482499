@use 'colors';

:root {
  --color-black: #{colors.$black};
  --color-white: #{colors.$white};
  --color-brand--blue: #{colors.$brand_blue};
  --color-brand--blue--light: #{colors.$brand_blue_light};
  --color-brand--orange: #{colors.$brand_orange};
  --color-brand--cream: #{colors.$brand_cream};
  --color-brand--green: #{colors.$brand_green};
  --color-brand--green--light: #{colors.$brand_green200};
  --color-shadow: #{colors.$shadow};
}
