@use '../modules/respond';

#main {
  max-width: 100vw;
}

.player--video {
  display: grid;
}

.player--video__video {
  margin: 0 auto;
  height: 100%;
  width: 320px !important;

  @include respond.respondAbove(md) {
    width: 640px !important;
  }
}

.element-invisible {
  display: none;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin-left: -1px;
  border: none;
  clip: rect(0, 0, 0, 0);
  padding: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

