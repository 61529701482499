
/**
 * Respond above a specific device or devices.
 *
 * @param string $size
 *   One of the following breakpoints:
 *     - xs: is the default at 0px;
 *     - sm: is equivalent to 576px at 16px font size.
 *     - md: is equivalent to 768px at 16px font size.
 *     - lg: is equivalent to 1024px at 16px font size.
 *     - xl: is equivalent to 1200px at 16px font size.
 *     - xxl: is equivalent to 1440px at 16px font size.
 */
@mixin respondAbove($size) {
  @if $size == xs {
    @media (min-width: 0px) { @content; }
  } @else if $size == sm {
    @media (min-width: 36em) { @content; }
  } @else if $size == md {
    @media (min-width: 48em) { @content; }
  } @else if $size == lg {
    @media (min-width: 64em) { @content; }
  } @else if $size == xl {
    @media (min-width: 75em) { @content; }
  } @else if $size == xxl {
    @media (min-width: 90em) { @content; }
  }
}
