@use '../modules/respond';

.nav__list--images {
  display: grid;
  width: 100%;
  height: 90vh;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-content: space-around;
  padding: 0;

  @include respond.respondAbove(md) {
    grid-template-columns: repeat(2, 20rem);
    column-gap: 5rem;
  }

  .item__link {
    text-decoration: none;
    color: var(--color-black);
    display: block;
    transition: 0 250ms all ease-in-out;
    padding: 1rem 1.25rem;

    &:focus,
    &:hover {
      outline: none;
      border-radius: 1rem;
      box-shadow: 0 0 1rem var(--color-shadow);
      background-color: var(--color-brand--blue--light);
    }
  }

  .item__image {
    height: 6rem;
    display: block;
    margin: 0 auto;
  }

  .item__heading {
    margin: 1rem auto;
    display: block;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}