@font-face {
  font-family: 'zb_zanutura_bookbold';
  src: url('fonts/zb_zanutura_bold-webfont.eot');
  src: url('fonts/zb_zanutura_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zb_zanuturabook';
  src: url('fonts/zb_zanutura_book-webfont.eot');
  src: url('fonts/zb_zanutura_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}