@use '../modules/respond';

.section--hw {
  margin: 0 1rem;
  display: grid;
  align-items: center;
  padding: 3rem 0;
}

#activity-list {
  margin: 0 auto;
}

.activity-list-wrapper {
  width: 24rem;

  @include respond.respondAbove(sm) {
    width: 30rem;
  }
}

.activity-item {
  border: 5px solid var(--color-black);
  background-color: var(--color-white);
  padding: 1rem;
  margin: 1rem 0;
}

.activity-item__control {
  display: grid;
  grid-template-columns: 1fr auto;
  transition: all 100ms ease-in-out;
  align-items: center;
}

.control__player {
  grid-row: 2;
  grid-column: span 2;
  margin-top: 1rem;
  align-items: center;
}

.control__toggle {
  background: transparent;
  border: none;

  .toggle--icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

wistia-player {
  cursor: pointer;
}
